<template>
<v-container>
    <v-row>
        <v-col cols="12">
            <h1>Completed</h1>
        </v-col>

        <v-col cols="12">
            <v-btn block large dark color="dark" @click="goTo()">Finish: {{ module.title }}
                <v-icon class="ml-2" color="warning">mdi-star</v-icon>
            </v-btn>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
//PAGE CURRENTLY NOT BEING USED CAN DELETE
// Mixins
import UserMixin from "@/mixins/user-mixin";
import SEOMixin from "@/mixins/seo-mixin";

export default {
    name: 'completed',
    
    data() {
        return {
            module: {}
        }
    },

    methods: {
        goTo() {
            this.$router.push({ name: 'module.menu', params: { id: this.$route.params.id } });
        }
    }
}
</script>

<style lang="scss">
</style>
